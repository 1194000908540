import React from "react";

const UserAction = ({ user, onDelete, onImpersonate }) => (
  <div className="flex item-center justify-center">
    <a
      href={`/users/${user.id}/edit`}
      className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 mr-2"
    >
      Edit
    </a>
    <button
      onClick={() => onImpersonate(user.id)}
      className="bg-green-500 text-white p-2 rounded hover:bg-green-700 mr-2"
    >
      Impersonate
    </button>
    <button
      onClick={() => onDelete(user.id)}
      className="bg-red-500 text-white p-2 rounded hover:bg-red-700"
    >
      Delete
    </button>
  </div>
);

export default UserAction;
