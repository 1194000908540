import React, { useState, useEffect } from "react";
import ChemicalForm from "./ChemicalForm";
import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.headers.common["X-CSRF-Token"] = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

const AddChemical = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    axios
      .get("/groups.json")
      .then((response) => {
        setGroups(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching groups:", error);
        toast.error("Failed to load groups");
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (formData) => {
    try {
      const response = await axios.post("/chemicals.json", {
        chemical: formData,
      });
      toast.success("Chemical created successfully");
      window.location.href = "/chemicals";
    } catch (error) {
      console.error("Error creating chemical:", error.response?.data || error);
      if (error.response?.data?.errors) {
        setErrors(Object.values(error.response.data.errors).flat());
      } else {
        setErrors(["An unexpected error occurred. Please try again."]);
      }
      toast.error(
        "Failed to create chemical. Please check the form for errors."
      );
    }
  };

  if (loading) return <div className="text-center mt-8">Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">
        Add New Chemical
      </h1>
      {errors.length > 0 && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Errors:</strong>
          <ul className="mt-2">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <ChemicalForm groups={groups} onSubmit={handleSubmit} />
    </div>
  );
};

export default AddChemical;
