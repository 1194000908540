import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import TableContainer from "../sites/tableContainer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

axios.defaults.headers.common["X-CSRF-Token"] = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

const ChemicalsPage = ({ initialChemicals }) => {
  const [chemicals, setChemicals] = useState(initialChemicals || []);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(!initialChemicals);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (!initialChemicals) {
          const chemicalsResponse = await axios.get("/chemicals.json");
          if (isMounted) setChemicals(chemicalsResponse.data);
        }
        const groupsResponse = await axios.get("/groups.json");
        if (isMounted) setGroups(groupsResponse.data);
        if (isMounted) setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted) {
          setLoading(false);
          toast.error("Failed to load data");
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [initialChemicals]);

  const handleGroupChange = (chemicalId, newGroupId) => {
    axios
      .patch(
        `/chemicals/${chemicalId}.json`,
        { chemical: { group_id: newGroupId } },
        {
          headers: { Accept: "application/json" },
        }
      )
      .then((response) => {
        if (response.data.success) {
          const updatedChemical = response.data.chemical;
          setChemicals((prevChemicals) =>
            prevChemicals.map((chem) =>
              chem.id === chemicalId
                ? {
                    ...chem,
                    group: updatedChemical.group,
                    group_id: updatedChemical.group.id,
                    cas_string: updatedChemical.cas_string,
                  }
                : chem
            )
          );
          toast.success(response.data.message, { autoClose: 3000 });
        } else {
          throw new Error("Failed to update chemical group");
        }
      })
      .catch((error) => {
        console.error("Error updating chemical group:", error);
        toast.error("Failed to update chemical group", { autoClose: 3000 });
      });
  };

  const handleDelete = (chemicalId) => {
    if (window.confirm("Are you sure you want to delete this chemical?")) {
      axios
        .delete(`/chemicals/${chemicalId}.json`, {
          headers: { Accept: "application/json" },
        })
        .then((response) => {
          if (response.data.success) {
            setChemicals((prevChemicals) =>
              prevChemicals.filter((chem) => chem.id !== chemicalId)
            );
            toast.success(response.data.message, { autoClose: 3000 });
          } else {
            throw new Error("Failed to delete chemical");
          }
        })
        .catch((error) => {
          console.error("Error deleting chemical:", error);
          toast.error("Failed to delete chemical", { autoClose: 3000 });
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }) => (
          <div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
            <a
              href={`/chemicals/${row.original.id}/edit`}
              className="text-blue-600 hover:text-blue-800"
              title={value}
            >
              {value}
            </a>
          </div>
        ),
        maxWidth: 500,
        width: 500,
      },
      {
        Header: "CAS",
        accessor: "cas_string",
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "Group",
        accessor: "group.title",
        Cell: ({ row }) => (
          <select
            value={row.original.group_id || ""}
            onChange={(e) => handleGroupChange(row.original.id, e.target.value)}
            className="block w-full bg-white border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select a group</option>
            {groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.title}
              </option>
            ))}
          </select>
        ),
        maxWidth: 170,
        width: 170,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <div className="space-x-2">
            <a
              href={`/chemicals/${value}/edit`}
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Edit
            </a>
            <button
              onClick={() => handleDelete(value)}
              className="text-red-600 hover:text-red-800 font-medium"
            >
              Delete
            </button>
          </div>
        ),
        maxWidth: 100,
        width: 100,
        disableSort: true,
      },
    ],
    [groups]
  );

  const data = useMemo(() => chemicals, [chemicals]);

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Listing chemicals</h1>
        <div className="space-x-4">
          <a
            href="/chemicals/new"
            className="bg-punch-green text-black font-semibold px-4 py-2 rounded hover:bg-other-turquoise mr-2"
          >
            Add chemical
          </a>
          <a
            href="/chemicals/order"
            className="bg-stat-blue text-black font-semibold px-4 py-2 rounded hover:bg-blue-500 mr-2"
          >
            Order Chemicals
          </a>
          <a
            href="/unmapped_chemicals"
            className="bg-deep-blue text-white font-semibold px-4 py-2 rounded hover:bg-indigo-800 mr-2"
          >
            Unmapped Chemicals
          </a>
        </div>
      </div>
      {data.length > 0 ? (
        <TableContainer columns={columns} data={data} />
      ) : (
        <p className="text-center text-gray-600">No chemicals found.</p>
      )}
    </div>
  );
};

export default ChemicalsPage;
